import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { useState } from 'react';
import portrait from './img/Mein_Kopf_A.png';
//import wood_box from './wooden-box.png';
//import DragMove from './components/DragMove';

const root = ReactDOM.createRoot(document.getElementById('root'));
var submits = 0;

function SendTelegramMessage() {
  const [inputs, setInputs] = useState({});
  const [hasError, setHasError] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(JSON.stringify(inputs));
    document.getElementById("MessageForm").reset();
    return fetch("https://schlattl-a.de/messagehandler/", {
      'method' : 'post',
      body : JSON.stringify(inputs)
    }).then(printSuccess).catch((e) => {
        setHasError(true);
    });
  }

  const printSuccess = (something) => {
    submits++;
    document.getElementById("Success").innerHTML = "Submitted! - " + submits;
  }

  if (hasError) return (<h1>ERROR in MessageForm</h1>);

  return (
    <div>
      <form id="MessageForm" name="MessageForm">
        <h3>Please type your message below:</h3>
        <p><textarea name="TelegramMessage" id="TelegramMessage" type="text" label="Nachrichteneingabe" style={{width: 300, height: 120}} onChange={handleChange}/></p>
        <p>Note: By default messages are sent anonymously, so if you want me to contact you back, leave a phone number, an e-mail-address or a snail mail address.</p>
        <button className='button' onClick={handleSubmit}>Send message!</button>
        <p id="Success"></p>
      </form>
    </div>
    )
}


function ShowPanicForm() {
  const [inputs, setInputs] = useState(false);
  const showForm = () => {
    if (inputs) {
      setInputs(false);
    }
    else {
      setInputs(true);
    }
  }
  return (
    <div>
      <button type="button" className="button" onClick={showForm}>Shutdown</button>
      {inputs ? <HandlePanic /> : null}
    </div>
  )
}


function HandlePanic() {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(JSON.stringify(inputs));
    document.getElementById("PanicForm").reset();
    return fetch("https://schlattl-a.de/messagehandler/", {
      'method' : 'post',
      body : JSON.stringify(inputs)
    }).then(response => response.json()).catch((e) => {
        alert("Caught error in handleSubmit - Panic");
    });
  }

  return (
      <div>
          <p>Please do NOT type a password, if you don't knot what you are doing!</p>
          <form id="PanicForm" name="PanicForm">
            <p><input name="PanicPassword" id="PanicPassword" type="password" label="Panic" onChange={handleChange}/></p>
            <button className='button' onClick={handleSubmit}>Senden</button>
          </form>
      </div>
  )
}


function MainMenu() {
  const [input, setInputs] = useState(false);
  const [hasError, setHasError] = useState(false);

  const showForm = () => {
    try {
      if (input) {
        setInputs(false);
      }
      else {
        setInputs(true);
      }
    } catch {
        setHasError(true);
    }
  }

  if (hasError) return (<h1>ERROR in MainMenu</h1>);

  return (
    <div key="Main">
      <h1>About me</h1>
      <img src={portrait} alt="Hier sollte eigentlich ein Logo zu sehen sein :(" style={{verticalAlign: "middle", height: "220px", width: "135px"}}/>
      <br></br>
      <br></br>
      <p>Hello, my name is Andreas and soon there will be more information about me.</p>
      <br></br>
      <br></br>
      <button className='button' onClick={showForm}>Send me a message</button>
      {input ? <SendTelegramMessage /> : null}
    </div>
  )
}

root.render(<MainMenu />);
